const config = require('next/config')();

export const metadata = {
  title: config?.publicRuntimeConfig?.siteName,
  description: 'Page description',
};

// import PageIllustration from 'components/page-illustration';
import Hero from 'components/hero-home';
// import Stats from 'components/stats';
// import Carousel from 'components/carousel';
// import Tabs from 'components/tabs';
// import Process from 'components/process';
// import PricingTables from 'components/pricing-tables';
// import TestimonialsBlocks from 'components/testimonials-blocks';
// import FeaturesBlocks from 'components/features-blocks';
// import Cta from 'components/cta';
import { useTranslation, LanguageSwitcher } from 'modules/I18n';
import Header from 'modules/common/layouts/Header';
import Footer from 'modules/common/layouts/Footer';

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      {/*  Page illustration */}
      <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1 overflow-hidden" aria-hidden="true">
        {/* <PageIllustration /> */}
      </div>
      <Hero />
      <Footer />
      {/* <Stats />
      <Carousel />
      <Tabs />
      <Process />
      <PricingTables />
      <TestimonialsBlocks />
      <FeaturesBlocks />
      <Cta />*/}
    </>
  );
}
