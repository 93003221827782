import React from 'react';
import { useTranslation, Link } from 'modules/I18n';
import useNotifier from 'modules/Notification/hooks/useNotifier';
import { Disclosure } from '@headlessui/react';
import { MdMenu, MdOutlineClose } from 'react-icons/md';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { useAuth, signOut } from 'modules/auth';
import Logo from 'modules/common/components/Logo';
import ConnectedMenu from '../components/ConnectedMenu';
import { ThemeToggle } from 'modules/design';

type HeaderProps = {
  // TODO
} & React.HTMLAttributes<HTMLDivElement>;

const Header: React.FC<HeaderProps> = ({ className, ...props }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { session, toggleModal } = useAuth();
  const { notify } = useNotifier();

  const handleSignOut = async () => {
    try {
      await signOut({
        callbackUrl: `${window.location.origin}`,
      });
      notify('success', 'You have been signed out');
    } catch (e: any) {
      notify('error', e.toString());
    }
  };

  return (
    <Disclosure as="header" className="simple" {...props}>
      {({ open }) => (
        <nav>
          <Link href="/" prefetch={false}>
            {/* <Image className="block h-8 w-auto" src="/logo/main.png" alt="Headlinker" height={50} width={50} /> */}
            <Logo />
          </Link>
          <ThemeToggle />
          {/* {!!session ? (
            <ConnectedMenu />
          ) : (
            <button onClick={() => toggleModal()} className="btn btn-sm btn--primary hidden md:flex" title={t('common.header:menu.signin.title')}>
              <span>{t('common.header:menu.signin.title')}</span>
            </button>
          )} */}
          {false && (
            <>
              <Disclosure.Button className="flex md:hidden">
                <span className="sr-only">{t('common.header:menu.sr.open')}</span>
                {open ? <MdOutlineClose className="block h-6 w-6" aria-hidden="true" /> : <MdMenu className="block h-6 w-6" aria-hidden="true" />}
              </Disclosure.Button>
              <Disclosure.Panel className="md:hidden">
                <div className="pt-2 pb-4 space-y-1">
                  {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                  {!!session ? (
                    <>
                      <Disclosure.Button
                        key="session_login"
                        as="button"
                        onClick={() => (!!session ? router.push('/my-missions') : toggleModal())}
                        className="bg-indigo-50 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      >
                        {t('common.header:menu.signin.title')}
                      </Disclosure.Button>
                      <Disclosure.Button
                        key="session_logout"
                        as="button"
                        onClick={handleSignOut}
                        className="border border-red-500 text-red-500 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      >
                        {t('common.header:menu.signout.title')}
                      </Disclosure.Button>
                    </>
                  ) : (
                    <Disclosure.Button
                      key="session_login"
                      as="button"
                      onClick={toggleModal}
                      className={classnames('bg-indigo-200 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium')}
                      title={t('common.header:menu.signin.title')}
                    >
                      <span className="w-full">{t('common.header:menu.signin.title')}</span>
                    </Disclosure.Button>
                  )}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </nav>
      )}
    </Disclosure>
  );
};

export default Header;
