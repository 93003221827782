import React from 'react';
import classNames from 'classnames';
import usePublicRuntimeConfig from '../hooks/usePublicRuntimeConfig';
import Image from 'next/image';

type LogoProps = {
  shape?: 'square' | 'wide';
} & React.HTMLAttributes<HTMLDivElement>;

const Logo: React.FC<LogoProps> = ({ className, shape = 'wide', ...props }) => {
  const { siteName } = usePublicRuntimeConfig();

  return (
    <figure className={classNames(className)} {...props}>
      <Image src="/logo/logo.png" width={50} height={50} alt={siteName} />
      {siteName}
    </figure>
  );
};

export default Logo;
